<template>
  <div class="findBar" @keydown="searchBarKeyDown">
    <div class="findBarInputContainer">
      <input ref="searchInput" v-model="searchContent" :style="{width: inputWidth ? inputWidth + 'px' : '216px'}" class="findInput toolbarField" title="Find" placeholder="在文档中查找..." data-l10n-id="find_input">
      <div class="splitToolbarButton">
        <button class="toolbarButton findPrevious" @click="previousMatch" title="Find the previous occurrence of the phrase" data-l10n-id="find_previous">
          ↑
<!--          {{useBriefBtn ? ' ↑ ' :'上一项' }}-->
        </button>
        <hr class="column-hr">
        <button class="toolbarButton findNext" @click="nextMatch" title="Find the next occurrence of the phrase" data-l10n-id="find_next">
          ↓
<!--          {{useBriefBtn ? ' ↓ ' :'下一项' }}-->

        </button>
      </div>
    </div>

    <div class="findbarOptionsOneContainer">
      <label v-if="enableOptions" class="toolbarLabel"
             :class="{'toolbarLabel--ative':highlightAll}" @click="highlightAll = !highlightAll;debounceSearchInPdf('findhighlightallchange')"
             data-l10n-id="find_highlight">全部高亮显示</label>
      <label v-if="enableOptions" class="toolbarLabel"
             :class="{'toolbarLabel--ative':caseSensitive}" @click="caseSensitive = !caseSensitive;debounceSearchInPdf()"
             data-l10n-id="find_match_case_label">区分大小写</label>
    </div>
    <div class="findbarOptionsTwoContainer">
      <label v-if="enableOptions" class="toolbarLabel"
             :class="{'toolbarLabel--ative':entireWord}" @click="entireWord = !entireWord;debounceSearchInPdf()"
             data-l10n-id="find_entire_word_label">字词匹配</label>
      <span id="" class="findResultsCount" v-show="searchTotal > 0">第 {{searchCurrentIndex}} 项，共匹配 {{searchTotal}} 项</span>
    </div>

    <div class="findbarMessageContainer" v-if="showMessage">
      <span class="findMsg">未匹配到内容</span>
    </div>
  </div>
</template>

<script>
import {colorLog, debounce, getBrowser} from "../../util";

export default {
  name: "PDFBriefSearcher",
  props:['pdfInfo','initialSearchContent','enableOptions','inputWidth'],
  watch:{
    searchContent(newVal,oldVal){
      if(newVal !== oldVal){
        this.debounceSearchInPdf();
      }
    },
  },
  data(){
    return {
      searchContent:'',

      //searchBar状态
      highlightAll:true,
      caseSensitive:false,
      entireWord:false,
      searchTotal:0,
      searchCurrentIndex:0,
      showMessage:false,

      debounceSearchInPdf:debounce((type = "find")=>{
        let pdfFindController = this.pdfInfo.pdfFindController;

        pdfFindController._eventBus.dispatch(type,{
          source:this,
          query:this.searchContent,
          phraseSearch: true,
          caseSensitive: this.caseSensitive,
          entireWord: this.entireWord,
          highlightAll: this.highlightAll,
        });
        this.$emit('onSearched');
      },500),

    }
  },
  created() {
    if(this.initialSearchContent){
      this.searchContent = this.initialSearchContent;
    }
    this.registerPdjInfoListeners();
  },
  mounted() {
    if(this.searchContent){
      this.debounceSearchInPdf();
    }
  },
  methods:{
    searchBarKeyDown(e){
      switch (e.keyCode) {
        case 13: // Enter
          if(e.shiftKey){
            this.previousMatch();
          }else{
            this.nextMatch();
          }
          break;
        case 27: // Escape
          this.$emit('closeSearchBar');
          this.clearSearchContent();
          break;
      }
    },
    clearSearchContent(){
      this.searchContent = '';
    },
    previousMatch(){
      // this.pdfInfo.pdfFindController._nextMatch(true);

      this.pdfInfo.pdfFindController._eventBus.dispatch("find",{
        source:this,
        type:"again",
        query: this.searchContent,
        phraseSearch: true,
        caseSensitive: this.caseSensitive,
        entireWord: this.entireWord,
        highlightAll: this.highlightAll,
        findPrevious: true
      })
      this.$emit('onSearched');

    },
    nextMatch(){

      // console.log(this.pdfInfo.pdfFindController)
      // this.pdfInfo.pdfFindController._nextMatch();

      this.pdfInfo.pdfFindController._eventBus.dispatch("find",{
        source:this,
        type:"again",
        query: this.searchContent,
        phraseSearch: true,
        caseSensitive: this.caseSensitive,
        entireWord: this.entireWord,
        highlightAll: this.highlightAll,
        findPrevious: false,
      })
      this.$emit('onSearched');
    },
    registerPdjInfoListeners(){
      this.pdfInfo.eventBus._on("updatefindmatchescount", (data)=>{
        this.searchCurrentIndex = data.matchesCount.current;
        this.searchTotal = data.matchesCount.total;

      });

      this.pdfInfo.eventBus._on('updatefindcontrolstate', (data)=>{
        this.searchCurrentIndex = data.matchesCount.current;
        this.searchTotal = data.matchesCount.total;
        //未匹配到
        if(this.searchTotal == 0 && data.state == 1){
          this.showMessage = true;
        }
        //回到默认空状态
        if(data.state == 0){
          this.showMessage = false;
        }
      });

      this.pdfInfo.eventBus._on('pagechanging',(data)=>{
        this.showMessage = false;
      })
    },
    focusOnInput(){
      this.$refs.searchInput.focus();
    }
  },

}
</script>

<style scoped>
.findBar{
  --findBarHeight:26px;
  display: flex;
  position: absolute;
  border: 1px solid #ccc;
  border-radius:2px;
  padding: 3px;
  height: var(--findBarHeight);
  align-items: center;
  white-space: nowrap;
  z-index: 99;
  background: #fff;
  box-sizing: content-box;
}
.findBarInputContainer{
  margin-right: 5px;
}
.findBarInputContainer{
  display: flex;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
}
.findInput{
  height: 100%;

  padding: 5px;
  box-sizing: border-box;
  border: none;
  outline: none;
  display: inline-block;
}
.findPrevious{
  border-left: 1px solid #ccc !important;
}

.findbarOptionsOneContainer .toolbarLabel{
  margin-right: 5px;
  line-height: 28px;
}
.toolbarButton{
  height: 100%;
  border: none;
  background-color: transparent;
}

.toolbarLabel{
  height: 100%;
  display: inline-block;
  border-radius: 2px;
  padding: 0 5px;
}
.column-hr{
  height: 100%;
  width: 1px;
  margin: 0;
  border: 0;
  background: #ccc;
  flex-shrink:0;
  flex-grow: 0;
}
.toolbarButton:hover{
  background: var(--hoverLightBlue);
}
.toolbarLabel--ative{
  background: var(--hoverDarkBlue) !important;
}
.toolbarLabel:hover{
  background: var(--hoverLightBlue);
}

.findResultsCount{
  margin-left: 10px;
  border-radius: 2px;
  padding: 0 5px;
  display: inline-block;
  background:var(--hoverDarkBlue);
  height: 100%;
}
.findMsg{
  height: 100%;
  color: rgba(251, 0, 0, 1);
  display: inline-block;
  padding: 0 5px;
}
.findbarOptionsOneContainer,.findbarOptionsTwoContainer,.findbarMessageContainer{
  font-size: 12px;
  height: 100%;
  line-height: var(--findBarHeight);
}
.splitToolbarButton{
  display: flex;
}
</style>
